import { ref } from "vue"
import { isFunction, isNumber, pick, omit, shake } from "radash"
import { useCollection } from "@/app/composable/useCollection"

/**
 * Split path params and data
 * @param {Object} args - Arguments object
 * @param {Array} params - Parameter definitions extracted from endpoint path
 * @returns {Object} - { params, data }
 */
function splitArgs(args, params = undefined) {
  let keys = ["query", ...(params || [])]
  let _params = pick(args, keys)
  const data = omit(args, keys)
  return shake({ params: _params, data })
}

/**
 * Helper to handle optional callback arguments
 * @param {Object|Function} args - Parameters or callback
 * @param {Function} cb - Callback function
 * @param {Array} params - Parameter definitions extracted from endpoint path
 * @returns {Object} - { params, data }
 */
function handleArgs(_args, params = undefined) {
  let [args, cb] = _args

  if (isFunction(args)) {
    cb = args
    args = {}
  } else if (isNumber(args)) {
    args = { id: args }
  }

  return { ...splitArgs(args, params), cb }
}

/**
 * Composable for CRUD operations on a resource endpoint
 * @param {Object} endpoint - API endpoint configuration
 * @param {Object} options - Options object with resource name
 * @returns {Object} CRUD methods and state
 */
export const useCrud = (endpoint, { name }) => {
  const controller = endpoint.controller
  const loading = endpoint.loading
  const meta = ref({})
  const model = ref({})
  const data = ref([])
  const collection = useCollection(data, { name })

  const dig = res => {
    return res ? (res[name] ?? res[controller]) : res
  }

  /**
   * Fetch list of resources
   * @param {Object} params - Query parameters
   * @param {Function} cb - Optional callback
   */
  async function index(...args) {
    const { params, cb } = handleArgs(args, endpoint.index.params)

    if (isFunction(cb)) endpoint.index.onResult(cb)

    return endpoint.index(params, res => {
      if (res?.meta) {
        meta.value = res.meta
      }

      const resData = res[`${name}s`]
      return collection.updateAll(resData)
    })
  }

  /**
   * Fetch single resource
   * @param {Object} params - Query parameters with id
   * @param {Function} cb - Optional callback
   */
  async function show(...args) {
    const { params, data, cb } = handleArgs(args, endpoint.show.params)

    if (isFunction(cb)) endpoint.show.onResult(cb)

    return endpoint.show(params || data, res => {
      const resData = dig(res, controller)

      collection.update(resData)
      model.value = resData

      return resData
    })
  }

  /**
   * Create new resource
   * @param {Object} params - Resource attributes
   * @param {Function} cb - Optional callback
   */
  async function create(...args) {
    const { params, data, cb } = handleArgs(args, endpoint.create.params)

    if (isFunction(cb)) endpoint.create.onResult(cb)

    const payload = shake({
      data: { [controller]: data },
      params
    })

    return endpoint.create(payload, res => {
      const resData = dig(res, controller)

      return collection.update(resData)
    })
  }

  /**
   * Update existing resource
   * @param {Object} params - Resource id and attributes
   * @param {Function} cb - Optional callback
   */
  async function update(...args) {
    const { params, data, cb } = handleArgs(args, endpoint.update.params)

    if (isFunction(cb)) endpoint.update.onResult(cb)

    const payload = shake({
      params,
      data: { [controller]: data }
    })

    return endpoint.update(payload, res => {
      const resData = dig(res)
      return collection.update(resData)
    })
  }

  /**
   * Delete resource
   * @param {Number} id - Resource id
   * @param {Object} params - Resource id
   * @param {Function} cb - Optional callback
   */
  async function destroy(...args) {
    console.log("destroy")

    const { params, data, cb } = handleArgs(args, endpoint.destroy.params)

    if (isFunction(cb)) endpoint.destroy.onResult(cb)

    return endpoint.destroy(params, res => {
      collection.remove(params)
    })
  }

  return {
    meta,
    model,
    data,
    controller,
    collection,
    loading,
    index,
    show,
    create,
    update,
    destroy,
    endpoint
  }
}
