import { defineStore } from "pinia"
import { $api } from "~/app/services/api"
import { useCrud } from "~/app/composable/useCrud"

export const useUsers = defineStore("users", () => {
  const crud = useCrud($api.apiV1Users, { name: "user" })

  return {
    index: crud.index,
    users: crud.data,
    loading: crud.loading,
    error: crud.error
  }
})
