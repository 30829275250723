import RibbonIcon from "@icons/ribbon--solid.svg?component"
import { TrophyIcon, UsersIcon } from "@heroicons/vue/24/solid"
import { ref, toValue } from "vue"
import { useArrayFilter } from "@vueuse/core/index.cjs"
import { useAuth } from "@/app/stores/auth"

export const useMenu = () => {
  const navItems = ref([
    {
      meta: { admin: true },
      icon: RibbonIcon,
      label: "Blueprints",
      url: "/blueprints"
    },
    {
      meta: { admin: true },
      icon: RibbonIcon,
      label: "Sessions",
      url: "/sessions"
    },
    {
      meta: { admin: true },
      icon: TrophyIcon,
      label: "Leaderboard",
      url: "/leaderboard"
    },
    { meta: { admin: true }, icon: UsersIcon, label: "Users", url: "/users" }
  ])

  const userNavItems = ref([
    { meta: {}, label: "Profile", url: "/profile" },
    { meta: { disabled: true }, label: "Dashboard", url: "/dashboard" }
  ])

  const auth = useAuth()
  const predicate = item => {
    if (toValue(auth.isAdmin)) {
      return !item.meta.disabled
    }

    return !(item.meta.disabled || item.meta.admin)
  }

  return {
    navigation: useArrayFilter(navItems, predicate),
    userNavigation: useArrayFilter(userNavItems, predicate)
  }
}
