const BlueprintIndex = () => import("./BlueprintIndex.vue")
const BlueprintShow = () => import("./BlueprintShow.vue")
const BlueprintEdit = () => import("./BlueprintEdit.vue")
const BlueprintCreate = () => import("./BlueprintCreate.vue")

export default {
  path: "/blueprints",
  children: [
    {
      path: "",
      name: "blueprints",
      component: BlueprintIndex,
      meta: { requiresAuth: true }
    },
    {
      path: ":id",
      component: BlueprintShow,
      meta: { requiresAuth: true }
    },
    {
      path: ":id/edit",
      component: BlueprintEdit,
      meta: { requiresAuth: true }
    },
    {
      path: "new",
      component: BlueprintCreate,
      meta: { requiresAuth: true }
    }
  ]
}
