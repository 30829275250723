import { createConsumer } from "@rails/actioncable"
import { inject, onBeforeMount, onBeforeUnmount } from "vue"

export const createCable = (app, options) => {
  return new Cable(app, options)
}

export function useCable() {
  return inject("$cable")
}

/**
 * @param subscription
 * @param {Object} subscription.handler
 * @param {Object} subscription.identifier
 */
export const useChannel = ({ handler, ...identifier }) => {
  const $cable = useCable()

  onBeforeMount(() => {
    $cable.subscribe(identifier, handler)
  })
  onBeforeUnmount(() => {
    $cable.unsubscribe(identifier)
  })

  const perform = (action, data) => {
    return $cable.perform(identifier, action, data)
  }

  return { perform }
}

export class ChannelMap {
  constructor() {
    this.subscriptions = new Map()
  }

  get(id) {
    return this.subscriptions.get(this._id(id))
  }

  set(id, channel) {
    return this.subscriptions.set(this._id(id), channel)
  }

  delete(id) {
    return this.subscriptions.delete(this._id(id))
  }

  _id(id) {
    return typeof id === "string" ? id : JSON.stringify(id)
  }
}

export class Cable {
  _cable = null
  _channels = new ChannelMap()

  constructor(app, options) {
    app.config.globalProperties.$cable = this
    app.provide("$cable", this)
    this.connect()
  }

  /**
   * given a subscription passed by a component register the handler
   * with the cable and store in the subscriptions map
   * @param {Object} subscription channel subscription object
   * @param {String} subscription.channel name of the channel
   * @param {...object} subscription.identifier rest of properties make up the identifier
   * @param handler {Object} handler object
   */
  subscribe(subscription, handler) {
    const existing = this._channels.get(JSON.stringify(subscription))

    if (!existing) {
      const sub = this._cable.subscriptions.create(subscription, handler)

      this._channels.set(sub.identifier, sub)
    }
  }

  /**
   * Unsubscribe a channel
   * @param id identifier of the channel to unsubscribe from
   */
  unsubscribe(id) {
    this._channels.get(id).unsubscribe()
    this._channels.delete(id)
  }

  /**
   * Perform an action on the action cable server
   * @param id {Object|String} identifier of the channel
   * @param action {String} action to perform
   * @param data {Object} action payload
   */
  perform(id, action, data) {
    this._channels.get(id).perform(action, data)
  }

  /**
   * Connect to the action cable server
   */
  connect() {
    this._cable = createConsumer()
  }

  /**
   * Disconnect from the action cable server
   */
  disconnect() {
    this._cable.disconnect()
  }

  /**
   * Reconnect to the action cable server
   */
  reconnect() {
    this._cable.connect()
  }
}
