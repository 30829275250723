<script setup>
import { useApi } from "~/app/services/api"
import { useRouteWatcher } from "~/app/utils"
import { useUsers } from "~/app/stores/user"
import Page from "../../components/Page.vue"

const userStore = useUsers()

const $api = useApi()

useRouteWatcher(async () => {
  await userStore.index()
})
</script>

<template>
  <Page>
    <template #title>Users</template>

    <div v-if="userStore.loading">Loading...</div>

    <div v-else>
      <table class="table">
        <thead>
          <th>Id</th>
          <th>Email</th>
          <th>Actions</th>
        </thead>
        <tr v-for="user in userStore.users">
          <td>{{ user.id }}</td>
          <td>{{ user.email }}</td>
          <td>
            <router-link :to="{ name: 'user', params: { id: user.id } }">
              View</router-link
            >
          </td>
        </tr>
      </table>
    </div>
  </Page>
</template>

<style scoped>
.table {
  @apply w-full;

  thead {
    @apply text-left;
  }

  tr:not(:first-of-type) {
    @apply border-t;
  }

  td {
    @apply py-4;
  }
}

.id {
}

.email {
  @apply font-bold;
}
</style>
