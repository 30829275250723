import { useRoute } from "vue-router"
import { watch } from "vue"
import { useDateFormat } from "@vueuse/core"

export const dateFormatter = (date, format = "DD MMM hh:mm A") => {
  return useDateFormat(date, format)
}

export const useFormattedDate = (format = "DD MMM hh:mm A") => {
  return date => {
    return useDateFormat(date, format)
  }
}

export const clone = obj => {
  return JSON.parse(JSON.stringify(obj))
}

export const useRouteWatcher = cb => {
  const route = useRoute()
  const watcher = () => route.params

  return watch(watcher, cb, { immediate: true })
}

const isObj = x => typeof x === "object" && x !== null
const isArr = x => Array.isArray(x)

export function buildQueryString(query, root = "") {
  const qs = new URLSearchParams()

  for (const [key, value] of Object.entries(query)) {
    if (isArr(value)) {
      value.forEach(val => {
        if (isObj(val)) {
          buildQueryString(val, `${key}[]`).forEach((_value, _key) => {
            let nestedRoot = root ? _key : _key
            qs.append(nestedRoot, _value)
          })
        } else {
          let nestedRoot = root ? `${root}[${key}][]` : `${key}[]`
          qs.append(nestedRoot, val)
        }
      })
    } else if (isObj(value)) {
      // Recursive call for nested objects
      let nestedRoot = root ? `${root}[${key}]` : key
      const nestedQS = buildQueryString(value, nestedRoot)
      // Merge the nested query string with the current one
      for (const [nestedKey, nestedValue] of new URLSearchParams(
        nestedQS
      ).entries()) {
        qs.append(nestedKey, nestedValue)
      }
    } else {
      // Appending root if it exists
      let fullKey = root ? `${root}[${key}]` : key
      qs.append(fullKey, value)
    }
  }
  return qs
}
