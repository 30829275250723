<script setup>
import { RouterLink } from "vue-router"
import { DisclosureButton } from "@headlessui/vue"

defineOptions({
  inheritAttrs: false
})

const props = defineProps({ ...RouterLink.props, inactiveClass: String })
</script>

<template>
  <router-link v-bind="props" custom v-slot="{ isActive, href, navigate }">
    <DisclosureButton
      v-bind="$attrs"
      @click="navigate"
      as="a"
      :href="href"
      :class="[isActive ? activeClass : inactiveClass]"
      :aria-current="isActive ? 'page' : undefined"
    >
      <slot />
    </DisclosureButton>
  </router-link>
</template>
