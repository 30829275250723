<script setup>
// Reset password
// Send password link
// Lock user
// Ban user
// Change email
// View user wallet
// Modify or manage user calls / action

import { useRouteWatcher } from "~/app/utils"
import { ref } from "vue"
import { useApi } from "~/app/services/api"

const user = ref(null)
const $api = useApi()

useRouteWatcher(async params => {
  const data = await $api.apiV1Users.show({ params: { id: params.id } })

  user.value = data.user
})
</script>

<template>
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <router-link :to="{ name: 'users' }"> Back to Users </router-link>

    <div v-if="user?.id">
      <h1>{{ user.email }}</h1>
      <p>{{ user.avatar }}</p>
      <p>{{ user.createdAt }}</p>
      <p>{{ user.phone }}</p>
      <p>{{ user.username }}</p>
      <pre>{{ user.wallet }}</pre>
    </div>
    <!-- <pre>{{ user }}</pre> -->
    <div>calls</div>
  </div>
</template>

<style scoped></style>
