<script setup>
import { DisclosureButton, DisclosurePanel } from "@headlessui/vue"
import { BellIcon } from "@heroicons/vue/24/outline"
import { useAuth } from "~/app/stores/auth"
import CurrentAvatar from "@/app/components/CurrentAvatar.vue"
import { useMenu } from "@/app/menu"
import MenuLink from "@/app/menu/MenuLink.vue"

const auth = useAuth()
const { navigation, userNavigation } = useMenu()
</script>

<template>
  <DisclosurePanel>
    <div class="space-y-1 pb-3 pt-2">
      <MenuLink
        v-for="item in navigation"
        :key="item.label"
        :to="{ path: item.url }"
        class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
        active-class="border-call-it-800 bg-ci-primary text-call-it-400"
        inactive-class="border-transparent text-gray-400 hover:border-gray-600 hover:bg-gray-900 hover:text-gray-50"
      >
        {{ item.label }}
      </MenuLink>
    </div>
    <div class="border-t border-gray-800 pb-3 pt-4">
      <div class="flex items-center px-4">
        <div class="flex-shrink-0">
          <DisclosureButton as="a" href="/profile">
            <div class="flex items-center">
              <CurrentAvatar size="thumb" class="mx-auto w-8 h-8" />
              <span class="text-base font-medium text-gray-400 ml-4">{{
                auth.userEmail
              }}</span>
            </div>
          </DisclosureButton>
        </div>

        <button
          type="button"
          class="relative flex-shrink-0 rounded-full bg-secondary p-1 text-gray-400 hover:text-gray-50 focus:outline-none focus:ring-2 focus:ring-call-it-500 focus:ring-offset-2 ml-auto"
        >
          <span class="sr-only">View notifications</span>
          <BellIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="mt-3 space-y-1">
        <DisclosureButton
          v-for="item in userNavigation"
          :key="item.label"
          as="a"
          :to="{ path: item.url }"
          class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-900 hover:text-gray-50 hover:border-l-4"
        >
          {{ item.label }}
        </DisclosureButton>
      </div>
      <div class="mt-3 space-y-1">
        <DisclosureButton
          as="a"
          @click.prevent="auth.signOut()"
          class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-900 hover:text-gray-50 hover:border-l-4"
        >
          Logout
        </DisclosureButton>
      </div>
    </div>
  </DisclosurePanel>
</template>

<style scoped></style>
