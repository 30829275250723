import {
  BoltIcon,
  ChatBubbleLeftRightIcon,
  TrophyIcon,
  ListBulletIcon,
  Cog6ToothIcon
} from "@heroicons/vue/24/solid"
import { useAuth } from "@/app/stores/auth.js"

export const route = {
  path: "/sessions",
  // component: Layout,
  children: [
    // Public
    {
      path: "",
      name: "sessions",
      component: () => import("./SessionIndex.vue"),
      meta: {
        redirectActive: true,
        guestAccessible: false
      }
    },
    {
      path: ":slug",
      name: "session",
      component: () => import("./SessionShow.vue"),
      beforeEnter: (to, from) => {
        if (
          to.name === "action" &&
          (from.name === undefined || from.name === "sessions")
        ) {
          if (useAuth().isAdmin) {
            return { path: `${to.fullPath}/admin` }
          }
        }
      },
      children: [
        {
          path: "join",
          name: "sessionRegistration",
          component: () => import("./live/Registration.vue"),
          meta: { redirectActive: true }
        },
        {
          path: "",
          name: "action",
          meta: {
            guestAccessible: true
          },
          component: () => import("./live/Action.vue")
        },
        {
          path: "leaders",
          name: "leaders",
          component: () => import("./live/Leaders.vue")
        },
        {
          path: "chat",
          name: "chat",
          passProps: true,
          component: () => import("./live/Chat.vue"),
          meta: { redirectActive: true }
        },
        {
          path: "history",
          name: "history",
          component: () => import("./live/CallHistory.vue")
        },
        {
          path: "admin",
          name: "admin",
          component: () => import("./live/Admin.vue"),
          meta: { requiresAuth: true }
        }
      ]
    },

    // Admin
    {
      path: ":id/edit",
      name: "edit",
      component: () => import("./SessionEdit.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "new",
      name: "create",
      component: () => import("./SessionCreate.vue"),
      meta: { requiresAuth: true }
    }
  ]
}

export const navigation = [
  {
    icon: Cog6ToothIcon,
    to: { name: "admin", params: route.params },
    label: "Admin",
    meta: { admin: true }
  },
  {
    icon: BoltIcon,
    to: { name: "action", params: route.params },
    label: "Action"
  },
  {
    icon: TrophyIcon,
    to: { name: "leaders", params: route.params },
    label: "Leaders",
    meta: { admin: true }
  },
  {
    icon: ChatBubbleLeftRightIcon,
    to: { name: "chat", params: route.params },
    label: "Chat",
    meta: { admin: true }
  },
  {
    icon: ListBulletIcon,
    to: { name: "history", params: route.params },
    label: "History"
  }
]

export default route
