import { toValue } from "vue"

export function useCollection(data, { name = "collection" } = {}) {
  function update(_data) {
    if (!_data || !_data.id) {
      console.warn(`Invalid data provided to update in ${name}`, _data)
      return null
    }
    const model = find(_data.id)
    model ? Object.assign(model, _data) : push(_data)
    return _data
  }

  function updateAll(_data) {
    if (!Array.isArray(_data)) {
      console.warn(`Invalid data provided to updateAll in ${name}`, _data)
      return null
    }
    data.value = _data
    return _data
  }

  function push(_data) {
    if (!_data) {
      console.warn(`Invalid data provided to push in ${name}`, _data)
      return null
    }
    data.value.unshift(_data)
    return _data
  }

  function findBy(fn) {
    if (typeof fn !== "function") {
      console.warn(
        `Invalid predicate function provided to findBy in ${name}`,
        _data
      )
      return null
    }
    return data.value.find(fn)
  }

  function find(id) {
    if (id == null) {
      console.warn(`Invalid id provided to find in ${name}\n`, _data)
      return null
    }
    return findBy(model => model.id === id)
  }

  function remove({ id }) {
    if (id == null) {
      console.warn(`Invalid id provided to remove in ${name}`, id)
      return null
    }
    const _data = toValue(data)
    const idx = _data.findIndex(model => model.id === id)
    if (idx === -1) {
      console.warn(`Item with id ${id} not found in ${name}`, _data)
      return null
    }
    const [el] = _data.splice(idx, 1)
    data.value = _data
    return el
  }

  return {
    updateAll,
    update,
    find,
    findBy,
    remove,
    push
  }
}
