import { createApp, inject, markRaw } from "vue"
import { createI18n, useI18n } from "vue-i18n"
import { createPinia, storeToRefs } from "pinia"
import { createRouter } from "~/app/router"
import { createCable } from "@/app/services/cable"
import App from "./App.vue"
import { createApi } from "@/app/services/api"
import { camel, mapEntries, objectify } from "radash"

import * as localeMessages from "~/locales.json"

import { plugin } from "@/devtools/plugin"
import { activeSession } from "@/app/services/activeSession"

class CallIt {
  static install(app) {
    app.provide("callIt", this.instance)
  }

  static get instance() {
    return new this()
  }

  constructor() {
    this.stuff = "blah"
  }
}

export const setupCallIt = app => {
  // app.config.performance = true

  const router = createRouter()
  const pinia = createPinia()
  const i18n = createI18n({
    legacy: false,
    locale: "en",
    messages: localeMessages
  })

  pinia.use(store => ({ router: markRaw(router) }))

  return app
    .use(CallIt)
    .use(pinia)
    .use(router)
    .use(createCable)
    .use(createApi)
    .use(i18n)
    .use(plugin)
  // .use(activeSession())
}

export const createCallIt = mountEl => {
  if (!mountEl) return

  const app = createApp({ ...App, name: "CallIt" }, buildProps(mountEl))

  return setupCallIt(app).mount(mountEl)
}

function buildProps(mountEl) {
  return {
    activeSession: document.querySelector("meta[name=singleton_session]")
      ?.content,
    csrfToken: document.querySelector("meta[name=csrf-token]")?.content,
    csrfParam: document.querySelector("meta[name=csrf-param]")?.content,
    ...objectify(
      Array.from(document.querySelectorAll("meta[name^=user]")),
      m => camel(m.name),
      m => JSON.parse(m.content)
    ),
    ...mapEntries(mountEl.dataset, (k, v) => [camel(k), JSON.parse(v)])
  }
}
