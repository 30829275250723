import * as VueRouter from "vue-router"
import { useAuth } from "./stores/auth"
import GuestInterstitial from "./components/GuestInterstitial.vue"

import Sessions from "@/app/views/sessions/index.js"
import Profile from "@/app/views/profile/index.js"
import Blueprints from "@/app/views/blueprints/index.js"
import Leaders from "@/app/views/leaders/index.js"
import Users from "@/app/views/users/index.js"

export const authGuard = async (to, from) => {
  if (to.meta.requiresAuth) {
    if (!useAuth().isAdmin) {
      return false
    }
  }
}

export const guestGuard = async (to, from) => {
  if (!to.meta.guestAccessible) {
    if (useAuth().isGuest) {
      return { name: "guestInterstitial" }
    }
  }
}

export const createRouter = () => {
  const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: [
      {
        name: "rootRedirect",
        path: "/",
        redirect: "/blueprints"
      },
      {
        name: "guestInterstitial",
        path: "/guest",
        meta: {
          guestAccessible: true
        },
        component: GuestInterstitial
      },
      Blueprints,
      Sessions,
      Profile,
      Leaders,
      Users
    ]
  })

  router.beforeEach((to, from) => console.log(from.name, " -> ", to.name))
  router.beforeEach(authGuard)
  router.beforeEach(guestGuard)

  return router
}
