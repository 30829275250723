import { reactive, computed, toRefs } from "vue"
import { defineStore } from "pinia"

export const useAuth = defineStore("auth", () => {
  const state = reactive({
    userRoles: [],
    userId: null,
    userEmail: null,
    csrfToken: null,
    userIsGuest: false
  })

  const isAdmin = computed(() => {
    return state.userRoles.includes("admin")
  })

  const isGuest = computed(() => {
    return state.userIsGuest
  })

  async function signOut() {
    await fetch("/users/sign_out", {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": state.csrfToken
      }
    })

    window.location.href = "/users/sign_in"
  }

  async function signIn(userDetails) {
    Object.assign(state, userDetails)
    return state
  }

  return {
    ...toRefs(state),
    signIn,
    signOut,
    isAdmin,
    isGuest
  }
})
