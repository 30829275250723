<script setup></script>

<template>
  <div class="interstitial">
    <h1>Welcome, guest user!</h1>
    <p>
      To view the rest of the site, you need to finish creating your account.
    </p>
    <a href="/users/guest_registration" class="btn btn--positive"
      >Create Account</a
    >
  </div>
</template>

<style scoped>
.interstitial {
  @apply max-w-lg
			m-auto
			text-center;

  h1 {
    @apply text-3xl
				mb-4;
  }
  p {
    @apply mb-8;
  }
  a {
    @apply w-fit;
  }
}
</style>
