import { defineStore } from "pinia"
import { useCrud } from "@/app/composable/useCrud"
import { $api } from "@/app/services/api"

export const useEvents = defineStore("events", () => {
  const crud = useCrud($api.apiV1Events, { name: "event" })
  const data = crud.data
  const meta = crud.meta
  const loading = crud.loading
  const error = crud.error

  async function fireStateEvent({ eventId, ...data }) {
    return await $api.apiV1States.update({ params: { eventId }, data })
  }

  return {
    data,
    meta,
    index: crud.index,
    create: crud.create,
    update: crud.update,
    destroy: crud.destroy,
    fireStateEvent,
    loading,
    error
  }
})
