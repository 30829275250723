import UserShow from "@/app/views/users/UserShow.vue"
import UserIndex from "@/app/views/users/UserIndex.vue"

export const route = {
  path: "/users",
  children: [
    {
      path: "",
      name: "users",
      component: UserIndex,
      meta: { redirectActive: true }
    },
    {
      path: ":id",
      name: "user",
      component: UserShow,
      meta: { redirectActive: true }
    }
  ]
}

export default route
