export default {
  path: "/profile",
  component: () => import("@/app/views/profile/Profile.vue"),
  name: "profile",
  children: [
    {
      path: "/account",
      component: "",
      meta: {
        guestAccessible: false
      }
    },
    { path: "/avatar", component: "" },
    { path: "/wallet", component: "" },
    { path: "/details", component: "" }
  ]
}
